
html, body {
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  display: flex;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes card-animation-right {
  from {
    opacity: 0;
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
